import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import toast from 'react-hot-toast'
import styled from 'styled-components'

import CVImage from './MiniComponents/CVImage'
import Modal from './Modal'
import Input from './controls/Input'

import MultiSelectDropdown from './controls/MultiSelectDropdown'
import RangeSlider from './RangeSlider'
import HeadLinesForm from './HeadLinesForm'

import { PagesContext } from '../context/FormPagesContext'
import { ModalContext } from '../context/ModalContext'

import useSyncCV from '../hooks/useSyncCV'

import apiClient from '../helpers/apiClient'

import { baseUrl } from '../constants/constant'

const UploadCv = ({ cvData, loading, setLoading, setCvData }) => {
  const history = useHistory()

  const {
    userDetails,
    setUserDetails,
    setOption,
    setItems,
    skills,
    setSkills,
    option,
    softSkills,
    setFullSkills,
    meta,
  } = useContext(PagesContext)

  const {
    handleModalToggle,
    handleModalToTrue,
    setOpenModal,
    updateCv,
    setUpdateCv,
  } = useContext(ModalContext)

  const { handleSyncCV } = useSyncCV()

  const [selectedSkill, setSelectedSkill] = useState([])

  const professionalHeadLines = userDetails.professionalHeadlines

  const handleFirstName = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      first_name: e.target.value,
    }))
  }

  const handleMiddleName = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      middle_name: e.target.value,
    }))
  }

  const handleLastName = (e) => {
    setUserDetails((prevState) => ({ ...prevState, last_name: e.target.value }))
  }

  const onUpload = async (e) => {
    // check if uploaded file is of accepted type.
    const filePath = e.target.value

    const allowedExtension = /(\.pdf)$/i
    if (!allowedExtension.exec(filePath)) {
      toast.error('Invalid file extension, only PDF files are accepted')
      e.target.value = ''
      return
    }

    // if the file pass the check
    // push the file inside a form data
    const formData = new FormData()
    formData.append('cv', e.target.files[0])
    setLoading(true)

    const parsedResult = await handleSyncCV(e.target.files[0])
    setUserDetails((prev) => ({
      ...prev,
      first_name: parsedResult.profile.first_name,
      middle_name: parsedResult.profile.middle_name,
      last_name: parsedResult.profile.last_name,
    }))

    setSkills(parsedResult.skills[0])

    // create an axios request to upload the file to cloudinary
    await apiClient
      .post('/upload-cv', formData)
      .then((res) => {
        // if the file is uploaded successfully check if there is an existing cv then delete.
        deletePreviousCv()
        const data = res.data.resp

        const slicedUrl = data.url.slice(0, -3) + 'jpg'

        // if cv is successfully uploaded to cloudinary, temporary save the details to local storage
        const cvDetails = {
          url: data.url,
          slicedUrl: slicedUrl,
          id: data.public_id,
          totalPageNumber: data.pages,
        }

        localStorage.setItem('cvDetails', JSON.stringify(cvDetails))
        setCvData(cvDetails)
        handleModalToTrue(true)
        setOpenModal(true)
        setLoading(false)
        e.target.value = ''
      })
      .catch((err) => {
        toast.error(
          err?.response?.message ||
            err?.response?.data?.message ||
            'Something went wrong. Please try again later.'
        )
        setLoading(false)
      })
  }

  const deletePreviousCv = () => {
    // delete the previous cv from cloudinary
    const cvDetails = JSON.parse(localStorage.getItem('cvDetails'))
    if (cvDetails) {
      apiClient
        .post('/delete-cv', {
          cv_public_id: cvDetails.id,
        })
        .then((res) => {
          handleModalToTrue(true)
          setOpenModal(true)
        })
        .catch((err) => {
          toast.error(
            err?.response?.message ||
              err?.response?.data?.message ||
              'Internal Server Error'
          )
        })
    } else {
      return null
    }
  }

  const saveCv = () => {
    // check is cv data exist on the state
    if (!cvData) return toast.error('Please upload a cv')
    // check if the user has entered a name
    if (!userDetails.first_name)
      return toast.error('Please enter your first name')

    if (!userDetails.last_name)
      return toast.error('Please enter your last name')

    if (professionalHeadLines?.length === 0)
      return toast.error('Please enter professional headlines')

    if (skills?.length === 0) return toast.error('Please enter skills')

    setFullSkills([skills, softSkills])
    const newMeta = JSON.stringify({
      ...meta,
      fullSkills: [skills, softSkills],
    })

    // save the cv to the database
    apiClient
      .post(`/applicant/save/cv-urls-id`, {
        cv_url: cvData.url,
        cv_public_id: cvData.id,
        first_name: userDetails.first_name,
        middle_name: userDetails.middle_name || '',
        last_name: userDetails.last_name,
        meta: newMeta,
        professional_headline: userDetails.professionalHeadlines,
      })
      .then((res) => {
        toast.success('Successfully uploaded CV.')
        const applicantCvData = localStorage.getItem('applicant-cv-data')

        const updatedApplicantCvData = {
          ...JSON.parse(applicantCvData),
          first_name: res.data.resp.first_name,
          middle_name: res.data.resp.middle_name,
          last_name: res.data.resp.last_name,
          meta: res.data.resp.meta,
          professional_headline: res.data.resp.professional_headline,
        }
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify(updatedApplicantCvData)
        )

        setTimeout(() => {
          if (updateCv) {
            history.push('/welcome')
            setUpdateCv(false)
          } else {
            history.push('/remote-setup')
          }
          handleModalToggle()
          setOpenModal(false)
        }, 1000)
      })
      .catch((error) => {
        console.log('Error', error)
        toast.error('An error occured during saving.')
      })
  }

  const onChangeHeadlines = (headline) => {
    setUserDetails({
      ...userDetails,
      professionalHeadlines: [...userDetails.professionalHeadlines, headline],
    })
  }

  const getTechnicalSkills = (ids) => {
    setOption([])
    setLoading(true)
    axios
      .get(`${baseUrl}/technical-skills?professionalHeadline_id=${ids}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const techSkills = res.data.resp

        const options = []
        techSkills.forEach((skill) => {
          options.push({
            value: skill.skills,
            label: skill.skills,
            skill: skill.skills,
            professional_headline_id: skill.professional_headline_id,
            experience: 1,
            id: skill.id,
            checked: skills.includes((x) => x.skill === skill.skills),
          })
        })

        const newSkills = [...skills]
        skills.forEach((skill) => {
          const techSkillIdx = techSkills.findIndex(
            (x) => x.skills === skill.skill
          )
          if (techSkillIdx < 0) {
            newSkills.splice(techSkillIdx, 1)
          }
        })

        setSkills(newSkills)
        setOption(options)

        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDeleteSkills = (id) => {
    setSelectedSkill(selectedSkill.filter((skill) => skill.id !== id))
    setSkills(skills.filter((skill) => skill.id !== id))
    const removedOptions = option.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: false,
        }
      } else {
        return {
          ...item,
        }
      }
    })
    setOption(removedOptions)
  }

  const getProfessionalHeadLines = () => {
    axios
      .get(`${baseUrl}/professional-headlines?withSkills=true`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const data = res.data.resp
        data.forEach((headline) => {
          const newHeadline = {
            id: headline.id,
            item: headline.professional_headline,
            status: 'current',
          }

          setItems((prevState) => {
            if (prevState.length > 0) {
              return [...prevState, newHeadline]
            } else {
              return [newHeadline]
            }
          })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    const professionalHeadlineId = []

    if (professionalHeadLines) {
      professionalHeadLines.forEach((headline) => {
        if (!professionalHeadlineId.includes(headline.id)) {
          professionalHeadlineId.push(headline.id)
        }
      })

      getTechnicalSkills(JSON.stringify(professionalHeadlineId))
    }
  }, [professionalHeadLines])

  useEffect(() => {
    const formattedSkills = selectedSkill.map((item) => {
      return {
        value: item.skill,
        label: item.skill,
        skill: item.skill,
        experience: item.experience,
        id: item.id,
        checked: true,
      }
    })
    setSkills(formattedSkills)
  }, [selectedSkill])

  useEffect(() => {
    setLoading(false)

    getProfessionalHeadLines()

    apiClient.get(`/user/${userDetails?.id}`).then((res) => {
      const name =
        res.data && res.data.data && res.data.data[0] && res.data.data[0].name
          ? res?.data?.data[0]?.name?.split(' ')
          : ''

      if (name !== '' || name?.length > 0) {
        setUserDetails({
          ...userDetails,
          first_name: name[0],
          last_name: name[1],
        })
      }
    })

    const _skills = []
    skills?.forEach((x) => {
      _skills.push({
        value: x.skill,
        label: x.skill,
        skill: x.skill,
        experience: x.experience,
        id: x.id,
        checked: true,
      })
    })
    setSelectedSkill(_skills)
  }, [])

  return (
    <Modal
      title="Upload CV"
      btnTitle="Save CV"
      btnFunc={saveCv}
      secondaryBtn
      secondaryBtnTitle={loading ? 'Loading...' : 'Change File'}
      noCancel
      containerClass="upload__cv"
    >
      <StyledUploadCV>
        <Grid>
          <div>
            <Label>
              First Name <span className="required">*</span>
            </Label>
            <Input
              type="text"
              value={userDetails.first_name}
              name="first name"
              onChange={handleFirstName}
            />
          </div>
          <div>
            <Label>Middle Name</Label>
            <Input
              type="text"
              value={userDetails.middle_name}
              name="middle name"
              onChange={handleMiddleName}
            />
          </div>
          <div>
            <Label>
              Last Name <span className="required">*</span>
            </Label>
            <Input
              type="text"
              value={userDetails.last_name}
              name="last name"
              onChange={handleLastName}
            />
          </div>
        </Grid>
        <InfoGroup>
          <HeadLinesForm
            onChangeHeadlines={onChangeHeadlines}
            headlines={userDetails.professionalHeadlines}
          />
        </InfoGroup>
        <InfoGroup>
          <Label>
            Skills
            {professionalHeadLines?.length === 0 && (
              <span> &nbsp; *Please enter professional headline</span>
            )}
          </Label>
          <br />
          <Label>Fill out your skills to help you get hired</Label>
          <MultiSelectDropdown
            options={option}
            loading={loading}
            selected={selectedSkill}
            setSelected={setSelectedSkill}
            disabled={professionalHeadLines?.length === 0}
            handleClick={handleDeleteSkills}
            limit={5}
          />
          {skills?.map(({ skill, experience, id }) => (
            <RangeSlider
              key={id}
              skill={skill}
              experience={experience}
              id={id}
              setSkills={setSkills}
              skills={skills}
              handleDelete={handleDeleteSkills}
            />
          ))}
        </InfoGroup>
        <InfoGroup>
          <Label>Preview CV</Label>
          <input type="file" id="cv" name="cv" hidden onChange={onUpload} />
          {cvData &&
            cvData.totalPageNumber &&
            [...Array(cvData.totalPageNumber).keys()].map((page) => (
              <CVImage
                key={page}
                url={cvData.slicedUrl}
                pageNumber={page + 1}
              />
            ))}
        </InfoGroup>
      </StyledUploadCV>
    </Modal>
  )
}

export default UploadCv

const StyledUploadCV = styled.div`
  width: 100%;

  span {
    .required {
      color: red;
    }
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
    width: 100%;

    .inputs {
      position: relative;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 0.75rem;
`

const InfoGroup = styled.div`
  margin-bottom: 0.75rem;

  h1 {
    font-weight: 300;
    font-size: 1.5rem;
    letter-spacing: 0.03em;
    line-height: 1.5;
    color: rgb(49, 55, 79);
  }

  button.keywordBtn {
    background: none;
    outline: none;
    border: none;
    font-size: 0.7rem;
    font-weight: 500;
    color: #858aa0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    &.remove {
      color: red;
    }

    &.mt {
      margin-top: 1rem;
    }

    &:hover {
      color: #31374f;
    }

    span {
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      background: #ffe2e9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;

      &.mr {
        margin-left: 0;
        margin-right: 0.5rem;
      }

      .icon {
        font-size: 0.7rem;
        color: #fd2055;
      }
    }
  }

  .inputs {
    &.mt {
      margin-top: 1.5rem;
    }

    &.rad {
      margin-bottom: 2rem;
    }

    label {
      font-size: 0.75rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 3%;
      margin-bottom: 0.75rem;
      line-height: 1.7;

      &.small-subtitle {
        font-weight: 300;
        color: #0e1324;
        letter-spacing: 0.03em;
        line-height: 1.5rem;
      }
    }

    .css-1rhbuit-multiValue {
      background: #f2f3f8;
      border-radius: 3px;
      font-size: 0.75rem;
      padding: 0.3rem 0.4rem;
    }

    .select,
    .input {
      display: block;
      margin-top: 0.7rem;
      width: 100%;
      height: 2.5rem;
      border-radius: 3px;
      border: 1px solid #aeb3c9;
      padding: 0 1rem;
      outline: none;
      color: #858aa0;
      cursor: pointer;
      font-size: 0.875rem;

      &.sm {
        height: 2rem;
        margin-top: 0;
        margin-left: 0.75rem;
        min-width: 200px;

        @media (max-width: 768px) {
          min-width: 100%;
        }
      }

      &.unique {
        border: none;
        margin-top: 0;
        height: 100%;
      }

      &::placeholder {
        color: #858aa0;
        font-size: 0.875rem;
        font-weight: 300;
      }
    }

    .react-tel-input {
      width: 100%;
      margin-top: 0.7rem;
      border-radius: 3px;

      .form-control {
        width: 100%;
        height: 2.5rem;
        border: 1px solid #aeb3c9;
        border-radius: 3px;
      }
    }

    .moreInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.7rem;
        font-weight: 300;
        color: #31374f;
        letter-spacing: 3%;
      }
    }

    .inputWrapper {
      position: relative;
      width: 100%;
      min-height: 60px;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      padding: 0.875rem;
      margin-top: 0.5rem;

      .cover {
        width: 100%;
        min-height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
      }

      .ck.ck-toolbar {
        background: none;
        border: none;
      }

      .ck.ck-toolbar__items {
        flex-wrap: wrap;
      }

      .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
      .ck.ck-editor__main > .ck-editor__editable.ck-focused {
        font-size: 0.75rem;
        color: #0e1324;
        font-weight: 300;
        letter-spacing: 0.03em;
        line-height: 1.5;
        border: none;
        outline: none;
        box-shadow: none;
      }

      .ck.ck-editor__editable_inline > :last-child {
        margin-bottom: var(--ck-spacing-large);
        word-break: break-word;
      }

      &.flex {
        display: flex;
        align-items: center;
        padding-bottom: 0.375rem;
      }

      input.new {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
      }
    }
  }
`

const Label = styled.label`
  font-size: 0.75rem;
  font-weight: 300;
  color: #0e1324;
  letter-spacing: 3%;
  margin-bottom: 0.75rem;
  line-height: 1.7;

  span {
    color: #dc2929;
  }
`
