/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState, useMemo } from 'react'

import { components } from 'react-select'
import { IoIosClose } from 'react-icons/io'
import styled from 'styled-components'
import toast, { Toaster } from 'react-hot-toast'
import axios from 'axios'
import Joi from 'joi'

import SelectableInput from '../../components/onboardingUpdate/SelectableInput'
import RangeSlider from '../../components/RangeSlider'
import ScrollToTop from '../../components/ScrollRestoration'
import CustomCreatableSelect from '../../components/CustomCreatableSelect'
import { StyledPersonalInfo } from './PersonalInfo'

import { PagesContext } from '../../context/FormPagesContext'

import { baseUrl } from '../../constants/constant'

const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 5 ? (
        props.children
      ) : (
        <div style={{ margin: 15 }}>Five Skills Completed</div>
      )}
    </components.Menu>
  )
}

export const SoftSkill = ({ text, id, setSoftSkills, softSkills }) => {
  const handleDelete = () => {
    const updatedSoftSkills = softSkills.filter(
      (softskill) => softskill.id !== id
    )
    setSoftSkills(updatedSoftSkills)
  }

  return (
    <StyledSoftSkill>
      <span>{text}</span>

      <IoIosClose onClick={handleDelete} className="icon" />
    </StyledSoftSkill>
  )
}

const Skills = () => {
  const {
    setPage,
    setReachPage,
    userDetails,
    skills,
    setSkills,
    softSkills,
    setSoftSkills,
    setFullSkills,
    softSkillOption,
    setOption,
  } = useContext(PagesContext)

  const [newOption, setNewOption] = useState([])
  const [loading, setLoading] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const validationSchema = useMemo(() => {
    return Joi.object({
      skills: Joi.array().label('Skills').min(1),
      softSkills: Joi.array().label('Soft Skills').min(1),
    })
  }, [])

  useEffect(() => {
    let applicantCVData = localStorage.getItem('applicant-cv-data')
    applicantCVData = JSON.parse(applicantCVData)

    const applicantCVMeta = JSON.parse(applicantCVData.meta)

    setSkills(applicantCVMeta.fullSkills[0])
    setSoftSkills(applicantCVMeta.fullSkills[1])
  }, [])

  useEffect(() => {
    const professional_headline_id = []

    userDetails.professionalHeadlines &&
      userDetails.professionalHeadlines.length > 0 &&
      userDetails.professionalHeadlines.forEach((headline) => {
        if (!professional_headline_id.includes(headline.id)) {
          professional_headline_id.push(headline.id)
        }
      })

    getTechnicalSkills(JSON.stringify(professional_headline_id))
  }, [])

  const getTechnicalSkills = (ids) => {
    setLoading(true)
    axios
      .get(`${baseUrl}/technical-skills?professionalHeadline_id=${ids}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const techSkills = res.data.resp.map((skill) => ({
          value: skill.skills,
          label: skill.skills,
          skill: skill.skills,
          professional_headline_id: skill.professional_headline_id,
          experience: 1,
          id: skill.id,
        }))
        setOption(techSkills)
        setNewOption(techSkills)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 5

  const handleDelete = (id) => {
    setSkills(skills.filter((skill) => skill.id !== id))

    const checkInput = document.getElementById(`skill ${id}`)

    if (checkInput) {
      checkInput.checked = false
    }
  }

  const handleGoBack = () => {
    setPage(0.6)
    setReachPage(0.6)
    localStorage.setItem('page', 0.6)
    localStorage.setItem('reachPage', 0.6)
  }

  const handleNext = () => {
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
    const oldMeta = cvData && cvData.meta ? JSON.parse(cvData.meta) : {}

    const formData = {
      skills,
      softSkills,
    }

    const result = validationSchema.validate(formData, { abortEarly: false })

    if (!result.error) {
      setFullSkills([skills, softSkills])
      const meta = JSON.stringify({
        ...oldMeta,
        fullSkills: [skills, softSkills],
      })
      localStorage.setItem(
        'applicant-cv-data',
        JSON.stringify({
          ...cvData,
          meta: meta,
        })
      )
      setPage(1)
      setReachPage(1)
      localStorage.setItem('page', 1)
      localStorage.setItem('reachPage', 1)
    } else {
      const validationErrors = result.error.details.reduce((acc, curr) => {
        acc[curr.context.key] = curr.message.replace(/"/g, '')
        return acc
      }, {})

      setFieldErrors(validationErrors)
      toast.error('Please correct the validation errors before proceeding.')
    }
  }

  return (
    <StyledPersonalInfo>
      <ScrollToTop />

      <h2>Skills</h2>
      <p className="small-subtitle">
        Fill out your skills and soft skills to help you get hired
      </p>

      <div className="inputs mt">
        <label htmlFor="skill">
          Technical Skills <span className="required">*</span>
        </label>
        <div className="moreInfo" style={{ marginBottom: '1rem' }}>
          <p>Choose up to 5 Technical Skills</p>
        </div>

        {/* Select input */}
        <SelectableInput
          loading={loading}
          newOption={newOption}
          setNewOption={setNewOption}
          className={fieldErrors?.skills && 'has-error'}
          errorMessage={fieldErrors?.skills}
        />
      </div>
      {skills
        ?.sort(function (a, b) {
          if (a.skill < b.skill) {
            return -1
          }
          if (a.skill > b.skill) {
            return 1
          }
          return 0
        })
        .map(({ skill, experience, id }) => (
          <RangeSlider
            key={id}
            skill={skill}
            experience={experience}
            id={id}
            setSkills={setSkills}
            skills={skills}
            handleDelete={handleDelete}
          />
        ))}

      {/* Soft skills */}
      <div className="inputs mt">
        <label htmlFor="skill">
          Soft Skills <span className="required">*</span>
        </label>
        <div className="moreInfo" style={{ marginBottom: '1rem' }}>
          <p>Choose up to 5 Soft Skills</p>
        </div>
        <CustomCreatableSelect
          components={{ Menu }}
          isMulti
          isValidNewOption={isValidNewOption}
          value={softSkills}
          options={softSkillOption}
          onChange={(value) => setSoftSkills(value)}
          errorMessage={fieldErrors?.softSkills}
          placeholder="Select up to 5 Soft Skills"
        />
      </div>

      <div className="buttonContainer">
        <button className="btn" onClick={handleGoBack}>
          Go Back
        </button>
        <button className="btn filled" onClick={handleNext}>
          Next Step
        </button>
      </div>

      <Toaster position="top-right" reverseOrder={false} />
    </StyledPersonalInfo>
  )
}

export default Skills

export const StyledSoftSkill = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 3px;
  background-color: #f2f3f8;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  span {
    font-size: 0.75rem;
    color: #0e1324;
    letter-spacing: 0.03em;
    font-weight: 400;
  }

  .icon {
    font-size: 0.875rem;
    color: #0e1324;
    cursor: pointer;
    margin-left: 0.5rem;
  }
`

export const StyledInputWrapper = styled.div`
  width: 100%;
  height: 4rem;
  border-radius: 3px;
  border: 1px solid #aeb3c9;
  padding: 1rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  .inputNew {
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
  }
`
