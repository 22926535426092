import React, { useContext } from 'react'

import styled from 'styled-components'

import FileInput from '../../components/FileInput'

import { PagesContext } from '../../context/FormPagesContext'

import useSyncCV from '../../hooks/useSyncCV'

const SettingsFour = () => {
  const { loading, handleSyncCV, images } = useSyncCV()

  const { setPage } = useContext(PagesContext)

  const handleUploadAndParseResumeHook = async (e) => {
    await handleSyncCV(e.target.files[0])
    setPage(1.2)
  }

  return (
    <StyledSettingsFour>
      <h2>Sync Resume</h2>
      <FileInput onChange={handleUploadAndParseResumeHook} />
      {<h1>{loading ? 'Processing Applicant CV' : 'Done!'}</h1>}
      {images.map((image, index) => (
        <img
          key={index}
          src={image && image}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        ></img>
      ))}
    </StyledSettingsFour>
  )
}

export default SettingsFour

export const StyledSettingsFour = styled.div`
  width: 100%;
  padding: 0 15px;
  padding-left: 30px;
`
