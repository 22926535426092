import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

import styled from 'styled-components'
import moment from 'moment'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'

import { StyledPersonalInfo } from './PersonalInfo'
import DownloadCVModal from '../welcome/components/DownloadCVModal'
import Slider from '../../components/Slider'
import SkillsUpdate from '../../components/onboardingUpdate/SkillsUpdate'
import EducationalBackgroundUpdate from '../../components/onboardingUpdate/EducationalBackgroundUpdate'
import EmploymentHistory from '../../components/onboardingUpdate/EmploymentHistory'
import ProfileDetailsUpdate from '../../components/onboardingUpdate/ProfileDetailsUpdate'

import { ModalContext } from '../../context/ModalContext'
import { PagesContext } from '../../context/FormPagesContext'

import { getFullName, getFromLocalStorage } from '../../utils/util'

import user from '../../assets/images/user.svg'
import pencil from '../../assets/icons/pencil.svg'

import { baseUrl } from '../../constants/constant'
import CharacterRefUpdate from '../../components/onboardingUpdate/CharacterRefUpdate'

const PreviewCV = () => {
  const history = useHistory()

  const { handleModalToggle, setCloseModal } = useContext(ModalContext)
  const {
    userDetails,
    educationalData,
    experienceState,
    skills,
    softSkills,
    file,
    userData,
    characterReferences,
    setPage,
    setSkills,
    setSoftSkills,
    setCharacterReferences,
  } = useContext(PagesContext)

  const [modal, setModal] = useState('')
  const [loading, setLoading] = useState(false)
  const [usar] = useState(JSON.parse(localStorage.getItem('user')) || [])

  const [sortedEducationData, setSortedEducationData] = useState([])
  const [sortedExperienceData, setSortedExperienceData] = useState([])

  const clearLocalStorage = () => {
    localStorage.removeItem('imgLink')
    localStorage.removeItem('cvImgLink')
    localStorage.removeItem('file')
  }

  const data = {
    first_name: userDetails.first_name,
    middle_name: userDetails.middle_name || '',
    last_name: userDetails.last_name,
    city: userDetails.city,
    professional_headline: JSON.stringify(userDetails.professionalHeadlines),
    professional_summary: JSON.stringify(userDetails.professionalSummary),
    phone_number: userDetails.contact,
    country: userDetails.country,
    meta: JSON.stringify({
      educationalData: educationalData || [],
      experienceState: experienceState || [],
      fullSkills: [skills, softSkills],
      ZIPCode: userDetails.ZIPCode,
      streetAddress: userDetails.streetAddress,
    }),
    characterReferences: JSON.stringify(characterReferences),
  }

  useEffect(() => {
    let applicantCVData = localStorage.getItem('applicant-cv-data')
    applicantCVData = JSON.parse(applicantCVData)

    const applicantCVMeta = JSON.parse(applicantCVData.meta)

    setSortedEducationData(
      applicantCVMeta.educationalData?.sort(
        (a, b) => new Date(b.from).valueOf() - new Date(a.from).valueOf()
      )
    )

    setSortedExperienceData(
      applicantCVMeta?.experienceState?.sort(
        (a, b) =>
          new Date(b.experienceFrom).valueOf() -
          new Date(a.experienceFrom).valueOf()
      )
    )

    setSkills(applicantCVMeta.fullSkills[0])
    setSoftSkills(applicantCVMeta.fullSkills[1])

    setCharacterReferences(JSON.parse(applicantCVData.character_reference))
  }, [])

  /**
   * Submits the form data to create a CV for the user.
   *
   * @return {void}
   */
  const handleSubmit = () => {
    try {
      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('middle_name', data.middle_name)
      formData.append('last_name', data.last_name)
      formData.append('city', data.city)
      formData.append('professional_headline', data.professional_headline)
      formData.append('professional_summary', data.professional_summary)
      formData.append('phone_number', data.phone_number)
      formData.append('country', data.country)
      formData.append('meta', data.meta)
      formData.append('character_reference', data.characterReferences)
      formData.append('cv_image_url', getFromLocalStorage('profileImgLink'))

      if (file) {
        formData.append('cvImage', file)
      }

      setLoading(true)
      axios
        .post(`${baseUrl}/user/${userData.id}/create-cv`, formData, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': `multipart/form-data;`,
          },
        })
        .then(() => {
          toast.success('CV updated successfully')

          setLoading(false)
          clearLocalStorage()

          history.push('/remote-setup')
        })
        .catch((err) => {
          setLoading(false)
          toast.error(
            err?.response?.data?.message || 'Some fields are not filled.'
          )
        })
    } catch (error) {
      console.log(error)
    }
  }

  const viewModal = (name) => {
    handleModalToggle()
    setModal(name)
  }

  return (
    <StyledPersonalInfo>
      <h2>Review your CV</h2>
      <p className="small-subtitle">
        Preview your CV and make sure it&apos;s all correct
      </p>

      <StyledDetails>
        <img
          src={pencil}
          alt="pencil icon"
          className="edit"
          onClick={() => viewModal('profile')}
        />

        {modal === 'profile' && <ProfileDetailsUpdate />}
        {modal === 'skill' && <SkillsUpdate />}
        {modal === 'education' && <EducationalBackgroundUpdate />}
        {modal === 'employment' && <EmploymentHistory />}
        {modal === 'characterRef' && <CharacterRefUpdate />}

        <div className="flex">
          <img
            src={getFromLocalStorage('profileImgLink') || user}
            className="user-image"
          />
          <div className="user-details">
            <h4>
              {getFullName(
                userDetails?.first_name,
                userDetails?.middle_name,
                userDetails?.last_name
              )}
            </h4>
            <p>{userDetails?.jobTitle}</p>
            <p>
              {userDetails?.city}, {userDetails?.country}
            </p>
            <p>{usar?.email}</p>
            <p>{userDetails?.contact}</p>
          </div>
        </div>

        <p
          className="more-details"
          dangerouslySetInnerHTML={{ __html: userDetails?.professionalSummary }}
          style={{ wordBreak: 'break-all' }}
        />
      </StyledDetails>

      {/* Educational Background */}
      <StyledDetails>
        <img
          src={pencil}
          onClick={() => viewModal('education')}
          alt="pencil icon"
          className="edit"
        />

        <h6>Educational Background</h6>

        {sortedEducationData?.map((data) => (
          <div key={data?.id}>
            <p>{data?.educationLevel}</p>
            <p>{data?.school}</p>
            <p style={{ marginBottom: '1.5rem' }}>
              {moment(data?.from).format('MMMM YYYY')} -{' '}
              {data?.currentStatus === 'currently enrolled' ||
              data?.to === null ||
              data?.to === '' ||
              data?.to === undefined ||
              data?.to === 'Invalid date'
                ? 'Present'
                : moment(data?.to).format('MMMM YYYY')}
            </p>
          </div>
        ))}
      </StyledDetails>

      {/* Employment History */}
      <StyledDetails>
        <img
          src={pencil}
          onClick={() => viewModal('employment')}
          alt="pencil icon"
          className="edit"
        />

        <h6>Employment History</h6>

        {sortedExperienceData?.map((data) => (
          <div key={data?.id} className={'lined-section first'}>
            <div className="small-title">{data?.jobTitle}</div>
            <p>{data?.companyName}</p>
            <p>
              {moment(data?.experienceFrom).format('MMMM YYYY')} -{' '}
              {data?.experienceCurrentStatus === 'Currently employed'
                ? 'Present'
                : moment(data?.experienceTo).format('MMMM YYYY')}
            </p>
            <p
              className="more-details new"
              dangerouslySetInnerHTML={{ __html: data?.jobDescriptionHtml }}
            />
          </div>
        ))}
      </StyledDetails>

      {/* Technical Skills */}

      <StyledDetails>
        <img
          src={pencil}
          alt="pencil icon"
          className="edit"
          onClick={() => viewModal('skill')}
        />

        <h6>Professional Headlines</h6>
        <div className="headlines">
          {userDetails.professionalHeadlines?.map(({ id, item }) => (
            <div key={id} className="headline__badge">
              {item}
            </div>
          ))}
        </div>

        <div className="two-grid">
          <div className="section">
            <h6>Technical Skills</h6>

            {skills?.map((skill) => {
              return (
                <div key={skill} className="skill">
                  <div className="small-title">{skill.skill}</div>
                  <Slider value={skill.experience} />
                </div>
              )
            })}
          </div>

          <div className="section">
            <h6>Personal Skills</h6>

            <div className="newSkill">
              {softSkills?.map(({ label }) => (
                <div key={label} className="skillCard">
                  {label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Toaster position="top-right" reverseOrder={false} />
      </StyledDetails>

      {/* Character references */}
      <StyledDetails>
        <img
          src={pencil}
          onClick={() => viewModal('characterRef')}
          alt="pencil icon"
          className="edit"
        />

        <h6>Character References</h6>

        {characterReferences?.map((data) => (
          <div key={data.id} className={'lined-section first'}>
            <div className="small-title">{data?.crName}</div>
            <p>{data.crEmail}</p>
            <p>{data.crPhone}</p>
          </div>
        ))}

        <DownloadCVModal />

        <div className="buttonContainer">
          <button className="btn" onClick={() => setPage(1)}>
            Go Back
          </button>
          <button className="btn filled" onClick={() => setCloseModal(true)}>
            Preview CV
          </button>
          <button className="btn filled" onClick={handleSubmit}>
            {loading ? 'Loading...' : 'Save CV'}
          </button>
        </div>
      </StyledDetails>
    </StyledPersonalInfo>
  )
}

export default PreviewCV

const StyledDetails = styled.div`
  width: 100%;
  border-top: 1px solid #eaeaf8;
  padding: 2rem 0;
  padding-bottom: 0;
  margin-top: 1.75rem;
  position: relative;

  .edit {
    position: absolute;
    top: 1.875rem;
    right: 0;
    cursor: pointer;
    width: 0.875rem;
    height: auto;
  }

  .flex {
    display: flex;
    align-items: flex-start;

    .user-image {
      width: 125px;
      height: 125px;
      object-fit: cover;
      margin-right: 1.5rem;
      border-radius: 50%;
    }

    .user-details {
      flex: 1;
    }
  }

  .two-grid {
    display: grid;
    grid-template-columns: 35% 50%;
    grid-gap: 3rem;
  }

  .headlines {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;

    .headline__badge {
      padding: 0.5rem 0.75rem;
      border: 0.5px solid rgb(49, 55, 79);
      text-align: center;
      font-size: 0.75rem;
      font-weight: 300;
      border-radius: 100px;
      margin-right: 0.7rem;
      margin-bottom: 0.7rem;
    }
  }

  .newSkill {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;

    .skillCard {
      padding: 1rem 1.2rem;
      border: 1px solid #dfdfe9;
      color: #0e1324;
      text-align: center;
      font-size: 0.7rem;
      font-weight: 300;
      border-radius: 100px;
      margin-right: 0.7rem;
      margin-bottom: 0.7rem;
    }
  }

  .skill {
    margin-top: 1rem;
  }

  .lined-section {
    padding-left: 1rem;
    border-left: 1px solid #ced4ee;
    position: relative;
    padding-bottom: 1.5rem;

    &.first {
      margin-top: 1.25rem;
    }

    &:last-child {
      border-left: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: -0.5px;
      transform: translate(-50%, -50%);
      width: 0.438rem;
      height: 0.438rem;
      border: 1px solid #ced4ee;
      border-radius: 50%;
      background: #fff;
    }
  }

  .small-title {
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.03em;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 0.03em;
    line-height: 1.5;
    color: #31374f;
  }

  h6 {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 1.5;
    color: #0e1324;
  }

  p {
    font-size: 0.75rem;
    font-weight: 300;
    margin-top: 0.6rem;
    color: #0e1324;
    letter-spacing: 0.03em;

    &.more-details {
      line-height: 1.6;
      margin-top: 1.5rem;

      &.new {
        margin-top: 0.7rem;

        &::first-letter {
          margin-left: 1.25rem;
        }
      }
    }
  }
`
